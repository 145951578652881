.news-container {
    padding: 20px;
    height: 100%;
    overflow: hidden;
  }
  
  .news-item {
    background-color: rgba(173, 216, 230, 0.5); /* Celeste transparente */
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 200px; /* Ajustar según sea necesario */
  }
  
  .news-image {
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    margin-bottom: 20px;
  }
  
  .news-details {
    margin-top: 10px;
  }
  
  .news-title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .news-date {
    font-size: 1em;
    color: #555;
  }
  
  /* Ocultar barra de desplazamiento y permitir desplazamiento del contenido */
  .p-datascroller-inline {
    height: 100%; /* Ajustar altura según sea necesario */
    overflow-y: hidden; /* Ocultar barra de desplazamiento vertical */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .p-datascroller-content {
    height: 100%; /* Ajustar altura según sea necesario */
    overflow-y: auto; /* Permitir desplazamiento del contenido */
    scrollbar-width: none; /* Ocultar barra de desplazamiento en Firefox */
  }
  
  /* Ocultar barra de desplazamiento en Webkit (Chrome, Safari) */
  .p-datascroller-content::-webkit-scrollbar {
    display: none;
  }
  