.body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin: 0;
    background-color: #ffffff;
  }
  
  .coin {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); /* Coin shadow */
    background-image: url('/public/img/logoinpro12.png'); /* Ruta de la imagen */

     /* Coin texture */
    animation: flip 1s infinite;
  }
  
  .engraving {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5); /* Engraving color */
    /* text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);  */
  }
  
  @keyframes flip {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  