.salas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-container {
  position: relative;
  width: 100%;
}

.imagensalas {
  width: 100%;
  height: auto;
}

.boton-bplay,
.boton-mac {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  background: none;
  border: none;
  width: 15%; /* Set a consistent width for both buttons */
}

.boton-bplay {
  right: 33%;
}

.boton-mac {
  left: 33%;
}

.salasbtnimg {
  width: 100%;
  height: auto;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .boton-bplay,
  .boton-mac {
    width: 30%; /* Increase the width for smaller screens */
    top: 30%; /* Position buttons at 30% from the top */
    transform: translateY(0); /* Remove translateY to properly align them */
  }

  .boton-bplay {
    left: 20%;
    right: auto;
  }

  .boton-mac {
    left: 50%;
    right: auto;
  }

  .boton-bplay.mobile,
  .boton-mac.mobile {
    position: absolute;
    top: 30%;
    width: 30%; /* Ensure buttons have sufficient width on mobile */
  }

  .salasbtnimg {
    width: 100%;
    height: auto;
  }
}
