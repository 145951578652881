/* General adjustments */
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  .sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 20px 0;
  }
  
  .sec__one h1 {
    text-align: center;
    display: block;
    margin: 0 auto;
    color: #4a5a63;
    font-family: 'Inter', sans-serif;
  }
  
  .navBar .logo img {
    float: left;
    max-height: 80px;
  }
  
  .is-active {
    color: #ff5800 !important;
  }
  
  .navBar .col-md-3 {
    z-index: 7;
  }
  
  ul.main-menu {
    display: inline-flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    float: right;
    z-index: 7;
  }
  
  ul.main-menu>li.menu-item:first-child {
    padding-left: 0;
    margin-left: 0;
  }
  
  .menu-item {
    line-height: 23px;
  }
  
  li {
    list-style-type: none;
  }
  
  .menu-item a {
    font-size: 1.7rem;
    font-weight: 60;
    line-height: 30px;
    position: relative;
    display: block;
    color: #ffffff;
    margin: 15px 10px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .header__middle__logo {
    display: inline-block;
    float: left;
  }

  .logonav {
    width: 85%;
     margin: 10px;
    height: auto;
    display: block; /* Remove extra space below the image */
    max-width: 100%; /* Ensure it doesn't exceed the container's width */
  }
  
  .header__middle__menus {
    display: inline-block;
    float: left;
  }
  
  .header__middle__search {
    width: 10%;
    display: inline-block;
    float: right;
    padding: 20px 5px;
    border-left: 1px solid lavender;
  }
  
  .header__middle {
    display: flex;
    width: 100%;
    float: left;
    border-bottom: 1px dashed lavender;
    position: relative;
  }
  
  .header__middle__logo>a>h1 {
    color: #07a8ff;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .header__middle__menus span.text {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 16px;
    color: #ff1800;
  }
  
  .sub__menus__arrows {
    position: relative;
  }
  
  .sub__menus__arrows svg,
  .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 13px;
  }
  
  .sub__menus {
    position: absolute;
    display: none;
    background: rgba(59, 160, 168, 1);
    border: 1px solid lavender;
    width: 227px;
    left: -17px;
    padding: 2px 0 0 0;
    z-index: 1000;
    box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
  }
  
  @media (max-width: 767px) {
    .header__middle .active {
      color: #00b7ff !important;
    }
  }
  
  @media (min-width: 992px) {
    .sub__menus__arrows:hover .sub__menus {
      display: block;
    }
    .sub__menus__arrows__full:hover .sub__menus {
      display: block;
      z-index: 1000000;
    }
  }
  
  .menu-item .sub__menus a {
    padding: 7px 0 0px 0;
    margin: 7px 27px;
  }
  
  .menu-item:hover>a {
    color: #020e30 !important;
  }
  
  .menu-item .sub__menus li:hover a {
    color: rgba(215, 65, 42, 1) !important;
  }
  
  .header__middle__logo img {
    max-width: 207px;
    margin: 10px 0 0 0;
  }
  
  @media (max-width: 991px) {
    .header__middle__logo {
      width: 77%;
    }
    .header__middle__menus {
      width: 20%;
    }
    .header__middle__logo img {
      max-width: 197px;
      margin: 15px 0 0 0;
    }
  
    .main-nav .menubar__button {
      display: flex !important;
      float: right;
background-color: rgb(42, 155, 149); 
      color: #fff;
      padding: 6px 7px 6px 7px;
      border-radius: 5px;
      margin: 15px auto 0;
      cursor: pointer;
      position: relative;
      z-index: 10037;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;
    }
  
    .main-nav .menubar__button svg {
      font-size: 2.5rem;
    }
  
    .main-nav ul.menuq2 {
      display: block !important;
      position: absolute;
      left: 0;
      z-index: 10007;
      background-color:  rgb(255, 255, 255);
      border-bottom: 3px solid #ff3d00;
      right: 0;
      padding-bottom: 17px;
      padding-top: 47px;
    }
  
    .main-nav .menu-item a {
      margin: 10px 10px;
      text-align: center;
      color: rgb(54, 54, 54);
    }
  
    .main-nav ul.main-menu {
      display: none;
    }
  
    .main-nav .sub__menus__arrows:hover .sub__menus {
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  
    .sub__menus__Active {
      display: block !important;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  
    .sub__menus__Active2 {
      display: block !important;
    }
  
    .menus__categorysss {
      display: none;
    }
  
    .menus__categorysss2 {
      display: none;
    }
  
    .menu-item .sub__menus__full a {
      text-align: left;
    }
  }
  
  /* Hide the hamburger menu in desktop mode */
  @media (min-width: 992px) {
    .main-nav .menubar__button {
      display: none;
    }
  }
  
  .header__middle__menus.bg-primary {
    background-color: var(--primary-color); /* Replace with your primary color */
  }
  
  .header__middle__menus.bg-white {
    background-color: white;
  }
  